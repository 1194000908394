<!--
 * @Description: 您可能感兴趣的求职
 * @Author: huacong
 * @Date: 2021-07-06 14:51:25
 * @LastEditTime: 2021-07-07 16:29:03
 * @LastEditors: huacong
-->
<template>
  <div>
    <div>
      <span>您可能感兴趣的求职信息</span>
    </div>
    <el-row :gutter="20">
      <el-col :span="12" v-for="item in partData" :key="item.id">
        <div class="resumebox" @click="goResume(item)">
          <el-row>
            <el-col :span="12" class="infobox fontHover">
              {{ item.certificate }}-{{ item.major }}
            </el-col>
            <el-col
              :span="12"
              class="infobox text-right font-red"
              style="font-size:16px"
            >
              {{ item.zs_price }}万
            </el-col>
            <el-col> {{ item.j_area_province }}-{{ item.j_area_city }} </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "urgentPartTime",
  data() {
    return {
      partData: [],
      searchInfo: {},
    };
  },
  computed: {
    ...mapState(["talcer2", "talmajor2"]),
  },
  created() {
    // this.getParttimeList();
  },
  methods: {
    ...mapMutations(["changeTalcer2", "changeTalmajor2"]),
    // 获取人员求职列表
    getParttimeList() {
      let talcer2 = this.$route.query.cer;
      let talmajor2 = this.$route.query.major;
      this.searchInfo.page = 1;
      this.searchInfo.limit = 10;
      this.searchInfo.certificate = talcer2;
      this.searchInfo.major = talmajor2;
      this.$http.post("index/resume/recommend", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
        }
      });
    },
    goResume(item) {
      let routeUrl = this.$router.resolve({
        name: "recommeInfo",
        query: {
          id: item.id,
          uid: item.uid,
          cer: item.certificate,
          major: item.major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    goPartTime() {
      this.$router.push({
        name: "partTimeIndex",
        params: { cer: this.talcer2, major: this.talmajor2 },
      });
    },
  },
  mounted() {
    this.getParttimeList();
  },
};
</script>
<style scoped>
.infobox {
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 12px;
}
.resumebox {
  height: 60px;
  padding: 20px;
  background: #dbe6f7;
  border-radius: 4px;
  margin-bottom: 20px;
}
.resumebox:hover {
  cursor: pointer;
  box-shadow: 0px -1px 0px 0px #e8e8e8;
}
</style>
